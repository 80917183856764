import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import ServiceDetailsContent from '../../components/ServiceDetails/ServiceDetailsContent'
 
const Details = () => {

    const technologies = [
        {
            id: 0,
            className: 'postgresql',
            technology: 'PostgreSQL',
        },
        {
            id: 1,
            className: 'elasticsearch',
            technology: 'Elasticsearch',
        },
        {
            id: 2,
            className: 'api',
            technology: 'theParse.api',
        },
        {
            id: 3,
            className: 'streams',
            technology: 'theParse streams',
        },
        {
            id: 4,
            className: 'analyse',
            technology: 'theParse.ai suite',
        },
        {
            id: 5,
            className: 'redis',
            technology: 'Redis',
        },
        {
            id: 6,
            className: 'databricks',
            technology: 'Databricks',
        },
        {
            id: 7,
            className: 'python',
            technology: 'Python',
        },
        {
            id: 8,
            className: 'aws_polly',
            technology: 'AWS Tools',
        },
        {
            id:9,
            className: 'iot_next',
            technology: 'IoT.NXT (in progress..)',
        },

    ];
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Service Details" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Service Details Data Science" 
            />
            <ServiceDetailsContent 
                technologies={technologies} 
                subtitle={"IoT & Advanced Analytics"}
                description={"We live Data Is The New Oil! Improve Machine Learning algorithms by studying underfitting, overfitting, training, validation, n-fold cross validation, testing, and how hyperparameters could improve performance. Need help exploring your data? Talk to us today."}
                nextsteps={"Our IoT and Advanced analytics suite comprises of a mixture of custom built algorithims and modern software tools used to analyse data with the much required flexibility."}
                email={"datascience@theparse.co.za"}
            />
            {/* <RelatedServices /> */}
            <Footer />
        </Layout>
    );
}

export default Details